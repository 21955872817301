import React, { useState } from "react";
import styles from "./Form.module.css"; // Import the CSS module

const Form: React.FC = () => {
  const [formData, setFormData] = useState({
    sys: "",
    dia: "",
    pul: "",
  });
  const [responseMessage, setResponseMessage] = useState(""); // State to store response message
  const [resultType, setResultType] = useState<string>('');
  const [values, setValues] = useState<number[]>([]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

 // Number formatting function
    const formatNumber = (num: number): string => {
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(num);
    };
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await fetch(`${apiUrl}/exec`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify(formData),
    });

    const result = await response.json();
    if (result.result === "success" && result.values) {
        setValues(result.values);
        setResultType('success');
        setResponseMessage("Successfully");
    } else {
        setResultType('error');
        setResponseMessage("Failed to submit the form");
    }
  };

  // Determine which class to apply based on resultType
  const messageClass = resultType === 'success' ? styles.successMessage : styles.errorMessage;

  return (
    <div className={styles.formContainer}>
        <a href="https://docs.google.com/spreadsheets/d/1msPbz-FYJz5s14iawkYoYREiB-_HEVwGUK6PnlSfigk/edit?usp=sharing" target="_blank" className={styles.linkb}>Sheet</a>
        <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
            <label htmlFor="sys">Systolic:</label>
            <input type="number" id="sys" name="sys" value={formData.sys} onChange={handleChange} required />
        </div>
        <div className={styles.formGroup}>
            <label htmlFor="dia">Diastolic :</label>
            <input type="number" id="dia" name="dia" value={formData.dia} onChange={handleChange} required />
        </div>
        <div className={styles.formGroup}>
            <label htmlFor="pul">Pulse :</label>
            <input type="number" id="pul" name="pul" value={formData.pul} onChange={handleChange} required />
        </div>
        <button type="submit"  className={styles.submitButton}>บันทึก</button>
        </form>
        {/* Display the response message in a div */}
        <div className={styles.responseMessage}>
          <p className={messageClass}>
            {responseMessage}
         </p>
         {resultType === 'success' && values.length > 0 && (
          <>
          <h4>ค่าเฉลี่ยวันนี้</h4>
          <ul>
            <li>SYS: {formatNumber(values[0])}</li>
            <li>DIA: {formatNumber(values[1])}</li>
            <li>PUL: {formatNumber(values[2])}</li>
          </ul>
          </>
        )}
        </div>
   </div>
  );
};

export default Form;
