// src/App.tsx
import React from 'react';
import Form from './components/Form';

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#333',
  fontSize: '2.5rem',
  margin: '20px 0',
  fontFamily: 'Arial, sans-serif',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
};
const App: React.FC = () => {
  return (
    <div className="App">
      <h1 style={ headerStyle }>Blood Pressure Note</h1>
      <Form />
    </div>
  );
};

export default App;
